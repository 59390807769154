import { getUser, User } from '@/actions/users'
import {
  keepPreviousData,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'

export const useGetUser = (): UseQueryResult<User, Error> => {
  return useQuery<User, Error>({
    queryKey: ['user'],
    queryFn: getUser,
    placeholderData: keepPreviousData,
  })
}
