import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

const env = createEnv({
  onValidationError: (errors) => {
    console.error(
      '❌ Invalid environment variables:',
      errors.map((error) => error.message),
    )
    throw new Error('❌ Invalid environment variables')
  },
  onInvalidAccess: (v: string) => {
    throw new Error(`❌ You can't access ${v} on the client side`)
  },
  server: {
    NODE_ENV: z.string(),
    TEAM_DOMAIN: z.string().url(),
    API_EDGE_URL: z.string().url(),
    API_PUBFEED: z.string().url(),
    CF_AUTHORIZATION_KEY: process.env.NODE_ENV.startsWith('dev')
      ? z.string()
      : z.string().optional(),
    STRIPE_US_ACCOUNT_ID: z.string(),
    STRIPE_GB_ACCOUNT_ID: z.string(),
    STRIPE_AU_ACCOUNT_ID: z.string(),
    STRIPE_CA_ACCOUNT_ID: z.string(),
    STRIPE_SG_ACCOUNT_ID: z.string(),
  },
  client: {
    NEXT_PUBLIC_HUBSPOT_PLATFORM_ID: z.string().optional(),
    NEXT_PUBLIC_IS_PRODUCTION: z.boolean(),
    NEXT_PUBLIC_PUBFEED_PREVIEW_URL: z.string().url().optional(),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV,
    TEAM_DOMAIN: process.env.TEAM_DOMAIN,
    API_EDGE_URL: process.env.API_EDGE,
    API_PUBFEED: process.env.API_PUBFEED,
    CF_AUTHORIZATION_KEY: process.env.CF_AUTHORIZATION_KEY,
    STRIPE_US_ACCOUNT_ID: process.env.STRIPE_US_ACCOUNT_ID,
    STRIPE_GB_ACCOUNT_ID: process.env.STRIPE_GB_ACCOUNT_ID,
    STRIPE_AU_ACCOUNT_ID: process.env.STRIPE_AU_ACCOUNT_ID,
    STRIPE_CA_ACCOUNT_ID: process.env.STRIPE_CA_ACCOUNT_ID,
    STRIPE_SG_ACCOUNT_ID: process.env.STRIPE_SG_ACCOUNT_ID,
    NEXT_PUBLIC_HUBSPOT_PLATFORM_ID:
      process.env.NEXT_PUBLIC_HUBSPOT_PLATFORM_ID,
    NEXT_PUBLIC_IS_PRODUCTION: process.env.NEXT_PUBLIC_ENV === 'production',
    NEXT_PUBLIC_PUBFEED_PREVIEW_URL:
      process.env.NEXT_PUBLIC_PUBFEED_PREVIEW_URL,
  },
})

export default env
